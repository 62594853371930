<template>
  <b-card class="shadow-sm">
    <p>
      Check your email for a link to reset your password. If it doesn’t appear
      within a few minutes, check your spam folder.
    </p>
  </b-card>
</template>

<script>
  export default {
    name: 'PassResetSent',

    methods: {
      handleSubmit () {
        this.$emit('submitting', this.emailCopy)
      }
    }
  }
</script>

<style>
</style>
