<template>
  <b-card class="shadow-sm">
    <p>
      Enter your registered email address and we will send you a password reset
      link.
    </p>
    <form @submit.prevent="handleSubmit">
      <b-form-input type="email" v-model="emailCopy"></b-form-input>
      <p v-if="error" class="text-danger">
        {{ error }}
      </p>
      <hr class="py-0" />
      <b-button type="submit" variant="primary" block>Submit</b-button>
    </form>
  </b-card>
</template>

<script>
  export default {
    name: 'PassForgotForm',

    props: {
      email: {
        type: String,
        required: true
      },
      error: {
        type: String,
        required: false
      }
    },

    data () {
      return {
        emailCopy: this.email
      }
    },

    methods: {
      handleSubmit () {
        this.$emit('submitting', this.emailCopy)
      },

      requestOtp () {
        this.$emit('requestOtp')
      }
    }
  }
</script>

<style>
</style>
