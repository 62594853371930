<template>
  <b-container>
    <b-row
      style="height: 100vh"
      class="justify-content-center align-items-center"
    >
      <b-col lg="4">
        <div class="w-100 text-center mb-4">
          <h4 class="mb-0 font-weight-bold text-primary">
            Reset your password
          </h4>
        </div>
        <pass-forgot-form
          v-if="!isSent"
          :email="email"
          :error="error"
          @submitting="forgotPass"
        ></pass-forgot-form>
        <pass-reset-sent v-if="isSent"></pass-reset-sent>
      </b-col>
    </b-row>
    <app-spinner ref="spinner"></app-spinner>
  </b-container>
</template>

<script>
  import UserService from '@/services/UserService'
  import PassForgotForm from '@/components/PassForgotForm'
  import PassResetSent from '@/components/PassResetSent'

  export default {
    name: 'AuthPassForgot',

    components: {
      PassForgotForm,
      PassResetSent
    },

    data () {
      return {
        email: '',
        error: '',
        isSent: false
      }
    },

    methods: {
      async forgotPass (email) {
        this.$refs.spinner.show()

        try {
          this.isSent = await UserService.forgotPass(email)
        } catch (error) {
          console.log(error.response.data)
          this.error = error.response.data.message
        }

        this.$refs.spinner.hide()
      }
    }
  }
</script>

<style></style>
