import api from './setup'

export default {

  async register (userData) {
    const { data } = await api.post('/auth/register', userData)
    return data
  },

  async login (data) {
    return api.post('/auth/login', data)
  },

  async findAll () {
    const { data } = await api.get('/users')
    return data
  },

  async findById (id) {
    const { data } = await api.get(`/users/${id}`)
    return data
  },

  async save (userData) {
    // If the userData has an id then run update user
    if (userData.id) {
      const { data } = await api.put(`/users/${userData.id}`, userData)
      return data
    }

    // Run create user
    const { data } = await api.post('/auth/register', userData)
    return data
  },

  async updatePassword (userData) {
    // Run create user
    const { data } = await api.put('/auth/update-password', userData)
    return data
  },

  async resetPassword (userData) {
    // Run create user
    const { data } = await api.put('/auth/reset-password', userData)
    return data
  },

  async confirmEmail (token) {
    const { data } = await api.get(`/auth/confirm-email/${token}`)
    return data
  },

  async resendConfirmLink (userId) {
    return await api.post(`/auth/resend-confirmation/${userId}`)
  },

  async checkOtp (loginData) {
    return await api.post('/auth/check-otp', loginData)
  },

  async resendOtp (userId) {
    return await api.post('/auth/resend-otp', { userId })
  },

  async forgotPass (email) {
    return await api.post('/auth/forgot-password', { email })
  },

  async confirmPassReset (token) {
    const { data } = await api.get(`/auth/confirm-password-reset/${token}`)
    return data
  },

  async resendPassResetLink (email) {
    return await api.post('/auth/forgot-password', { email })
  },

  async delete (id) {
    return api.delete(`/users/${id}`)
  }

}
